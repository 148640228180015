import React from 'react';

import { api } from 'api';

import { AvatarUser } from 'components/Avatar';
import { Button } from 'components/Button';
import { useText } from 'components/Language';

import css from './Header.module.css';

export interface HeaderUserActionsProps {
  me: api.User;

  onClickCreateEvent?: (event: React.MouseEvent) => Promise<void> | void;
  onClickNotifications?: (event: React.MouseEvent) => Promise<void> | void;
  onClickProfile?: (event: React.MouseEvent) => Promise<void> | void;
}

export const HeaderUserActions = React.memo<HeaderUserActionsProps>(
  ({ me, ...props }) => {
    const text = useText(state => state.controls.loginModal.gameSettings);

    return (
      <section className={css.rightSectionContainer}>
        <Button className={css.actionButton} onClick={props.onClickCreateEvent}>
          {text.createGroup}
        </Button>
        {/* <Link href={links.notifications()}>
          <a>
            <ButtonIcon
              onClick={props.onClickNotifications}
              Icon={Icon.Notification}
            />
          </a>
        </Link> */}
        <AvatarUser
          className={css.avatarImg}
          size={32}
          user={me}
          onClick={props.onClickProfile}
        />
      </section>
    );
  },
);
