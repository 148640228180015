import React, { useMemo } from 'react';

import cx from 'classnames';

import { api } from '../../../api';

import { Text } from '../../../components/Text';

import { HeaderSearch, HeaderSearchProps } from '../HeaderSearch';

import css from './Header.module.css';

import { HeaderAuthActions } from './HeaderAuthActions';
import { HeaderUserActions } from './HeaderUserActions';

import type { HeaderAuthActionsProps } from './HeaderAuthActions';
import type { HeaderUserActionsProps } from './HeaderUserActions';

export interface HeaderProps
  extends HeaderAuthActionsProps,
    HeaderSearchProps,
    Omit<HeaderUserActionsProps, 'me'> {
  withBackground?: boolean;

  me: api.Maybe<api.User>;
  title: string;
}

export const Header = React.memo<HeaderProps>(
  ({ me, title, withBackground = false, ...props }) => {
    const RightSection = useMemo(() => {
      if (!me) {
        return (
          <HeaderAuthActions
            onClickLogin={props.onClickLogin}
            onClickSignUp={props.onClickSignUp}
          />
        );
      }

      return (
        <HeaderUserActions
          me={me}
          onClickCreateEvent={props.onClickCreateEvent}
          onClickNotifications={props.onClickNotifications}
          onClickProfile={props.onClickProfile}
        />
      );
    }, [me, props]);

    return (
      <header
        className={cx(css.container, { [css.backgroundContainer]: withBackground })}
      >
        <Text.H1Memo bold className={css.pageTitleText}>
          {title}
        </Text.H1Memo>

        <HeaderSearch
          defaultValue={props.defaultValue}
          onRequestSearch={props.onRequestSearch}
        />
        {RightSection}
      </header>
    );
  },
);
