import React, { useCallback, useMemo } from 'react';

import cx from 'classnames';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { analytics } from 'analytics';
import { useApp } from 'components/ContextApp';
import { useEventModal } from 'components/ContextModal/event';
import { useOnboardingModal } from 'components/ContextModal/onboarding';
import { IS_BETA, SocialMedia } from 'const';

import { ClientLanguage } from 'utils/language';
import { useScrollRestoration } from 'utils/main';

import { Header } from './Header';
import { QUERY_USERNAME_SEARCH_KEY, useOpenAppLinksModal } from './helpers';
import css from './LayoutApp.module.css';
import { Navigation, NavigationPage } from './Navigation';
import { FooterLink } from './NavigationFooter';

import type { api } from 'api';
import type { SupportedGameId } from 'api/types';
import type { Meta } from 'utils/meta';

export interface LayoutAppProps {
  me: api.Maybe<api.User>;

  title: string;
  meta?: Meta;

  className?: string;
  children: React.ReactNode;
  withHeaderBackground?: boolean;

  onClickNavigationItem?: (
    page: NavigationPage,
    event: React.MouseEvent,
  ) => Promise<void> | void;
  onClickNavigationGame?: (
    game: SupportedGameId,
    event: React.MouseEvent,
  ) => Promise<void> | void;
  onClickNavigationLogo?: (event: React.MouseEvent) => Promise<void> | void;
  onClickCreateGroup?: (event: React.MouseEvent) => Promise<void> | void;
  onClickLogin?: (event: React.MouseEvent) => Promise<void> | void;
  onClickProfile?: (event: React.MouseEvent) => Promise<void> | void;
  onClickNotifications?: (event: React.MouseEvent) => Promise<void> | void;
  onSearch?: (value: string, event: React.FormEvent) => Promise<void> | void;
}

export const LayoutApp: React.FC<LayoutAppProps> = ({
  children,
  me,

  title,
  meta,
  className,
  withHeaderBackground = false,

  onClickNavigationItem,
  onClickNavigationGame,
  onClickNavigationLogo,
  onClickCreateGroup,
  onClickLogin,
  onClickProfile,
  onClickNotifications,
  onSearch,
}) => {
  const app = useApp();
  const router = useRouter();

  const onboardingModal = useOnboardingModal();
  const eventModal = useEventModal();

  useScrollRestoration(router);
  useOpenAppLinksModal();

  const searchDefaultValue = router.query[QUERY_USERNAME_SEARCH_KEY] as
    | string
    | undefined;

  const handleClickFooterLanguageSelect = useCallback(() => {
    analytics.clickLanguage();
  }, []);

  const handleRequestLanguageChange = useCallback(
    (language: ClientLanguage) => app.setLanguage(language),
    [],
  );
  const handleClickFooterSocialMedia = useCallback((socialMedia: SocialMedia) => {
    switch (socialMedia) {
      case 'Twitter':
        analytics.clickTwitter();
        break;
      case 'Discord':
        analytics.clickDiscord();
        break;
    }
  }, []);

  const handleClickFooterLink = useCallback((link: FooterLink) => {
    switch (link) {
      case 'bot':
        return analytics.clickAddBot('footer');
    }
  }, []);

  const handleClickLogin = useCallback(
    async (event: React.MouseEvent) => {
      if (onClickLogin) {
        await onClickLogin(event);
        if (event.defaultPrevented) {
          return;
        }
      }

      onboardingModal.open({});
    },
    [onClickLogin],
  );

  const handleClickProfile = useCallback(
    async (event: React.MouseEvent) => {
      if (onClickProfile) {
        await onClickProfile(event);
        if (event.defaultPrevented) {
          return;
        }
      }
    },
    [onClickProfile],
  );

  const handleClickNotifications = useCallback(
    async (event: React.MouseEvent) => {
      if (onClickNotifications) {
        await onClickNotifications(event);
        if (event.defaultPrevented) {
          return;
        }
      }
    },
    [onClickNotifications],
  );

  const handleClickCreateGroup = useCallback(
    async (event: React.MouseEvent) => {
      if (onClickCreateGroup) {
        await onClickCreateGroup(event);
        if (event.defaultPrevented) {
          return;
        }
      }

      eventModal.open({});
    },
    [onClickCreateGroup],
  );

  const handleNavigationLogoClick = useCallback(
    async (event: React.MouseEvent) => {
      if (onClickNavigationLogo) {
        await onClickNavigationLogo(event);
        if (event.defaultPrevented) {
          return;
        }
      }

      analytics.clickLogo();
    },
    [onClickNavigationLogo],
  );

  const handleNavigationGameClick = useCallback(
    async (game: SupportedGameId, event: React.MouseEvent, isMyGame: boolean) => {
      if (onClickNavigationGame) {
        await onClickNavigationGame(game, event);
        if (event.defaultPrevented) {
          return;
        }
      }

      if (isMyGame) {
        analytics.myGameClick(game);
      } else {
        analytics.lfgGameClick(game);
      }
    },
    [onClickNavigationGame],
  );

  const handleNavigationItemClick = useCallback(
    async (item: NavigationPage, event: React.MouseEvent) => {
      if (onClickNavigationItem) {
        await onClickNavigationItem(item, event);
        if (event.defaultPrevented) {
          return;
        }
      }

      // Write analytics event
      switch (item) {
        case 'home':
          analytics.clickHome();
          break;
        case 'myGroups':
          analytics.clickMyGroup();
          break;
      }
    },
    [onClickNavigationItem],
  );

  const handleRequestSearch = useCallback(
    async (value: string, event: React.FormEvent) => {
      if (onSearch) {
        await onSearch(value, event);
        if (event.defaultPrevented) {
          return;
        }
      }

      const link = `/players?user_name=${encodeURI(value)}`;
      router.push(link);
    },
    [onSearch],
  );

  const Meta = useMemo(() => {
    const devQuery = IS_BETA ? '?dev=true' : '';

    return (
      <Head>
        {meta?.title || meta?.og?.title ? (
          <title>{meta.title || meta?.og?.title}</title>
        ) : null}
        {meta?.og?.description ? (
          <meta content={meta.og.description} name="description" />
        ) : null}
        {meta?.og?.title ? (
          <meta content={meta.og.title} property="og:title" />
        ) : null}
        {meta?.og?.description ? (
          <meta content={meta.og.description} property="og:description" />
        ) : null}
        {meta?.og?.image ? (
          <meta content={meta.og.image + devQuery} property="og:image" />
        ) : null}
        {meta?.og?.image ? (
          <meta content="image/png" property="og:image:type" />
        ) : null}
        {meta?.og?.image ? (
          <meta content="summary_large_image" name="twitter:card" />
        ) : null}
        {meta?.og?.image ? (
          <meta content={meta.og.image} name="twitter:image" />
        ) : null}
      </Head>
    );
  }, [
    meta?.title,
    meta?.description,
    meta?.og?.title,
    meta?.og?.description,
    meta?.og?.image,
  ]);

  const myGames = useMemo(
    () => (me?.lfg?.map(lfg => lfg.gameId) as SupportedGameId[]) || [],
    [me?.lfg?.length],
  );

  const query = useMemo(() => router.query, [router.query]);

  return (
    <>
      {/* TODO: move to ContextModals */}
      <main className={css.container} id="layout-app">
        {Meta}
        <aside className={css.navigationContainer}>
          <Navigation
            isAuthed={Boolean(me)}
            language={app.language}
            myGames={myGames}
            pathname={router.pathname}
            query={query}
            onClickFooterLink={handleClickFooterLink}
            onClickFooterSocialMedia={handleClickFooterSocialMedia}
            onClickLanguageSelect={handleClickFooterLanguageSelect}
            onGameClick={handleNavigationGameClick}
            onItemClick={handleNavigationItemClick}
            onLogoClick={handleNavigationLogoClick}
            onRequestChangeLanguage={handleRequestLanguageChange}
          />
        </aside>

        <section className={cx(css.wrapper, className)}>
          <Header
            defaultValue={searchDefaultValue}
            me={me}
            title={title}
            withBackground={withHeaderBackground}
            onClickCreateEvent={handleClickCreateGroup}
            onClickLogin={handleClickLogin}
            onClickNotifications={handleClickNotifications}
            onClickProfile={handleClickProfile}
            onClickSignUp={handleClickLogin}
            onRequestSearch={handleRequestSearch}
          />
          {children}
        </section>
      </main>
    </>
  );
};
