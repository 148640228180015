import React from 'react';

import { Button } from '../../../components/Button';
import { useText } from '../../../components/Language';

import css from './Header.module.css';

export interface HeaderAuthActionsProps {
  onClickSignUp?: (event: React.MouseEvent) => Promise<void> | void;
  onClickLogin?: (event: React.MouseEvent) => Promise<void> | void;
}

export const HeaderAuthActions = React.memo<HeaderAuthActionsProps>(props => {
  const text = useText(state => state.controls.loginModal);

  return (
    <section className={css.rightSectionContainer}>
      <Button
        className={css.actionButton}
        color="green"
        onClick={props.onClickSignUp}
      >
        {text.signUp}
      </Button>
      <Button
        className={css.actionButton}
        color="secondary"
        onClick={props.onClickLogin}
      >
        {text.action}
      </Button>
    </section>
  );
});
